import React, { useEffect, useState } from 'react'
import { BsTelephoneFill } from 'react-icons/bs';
import Sel from '../inps/Sel';
import { currentUser0, officesList0, notiList0, lastCall0 } from '../../lib/states';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';


import { get, post, put } from '../../lib/apiHandler';
import objectToQueryParams from '../../lib/objToParams';
import SelectPro from '../inps/SelectPro-new';
import SelectProMulty from '../inps/SelectProMulty-new';
import isoToCountry from '../../lib/isoToCountry';
import SelectLang from '../inps2/SelectLang';


export default function Tr({
    clientData,
    setCurrentData,
    clientDataIndex,
    allThs,
    ths,
    i,

    setListCheked,
    listCheked,
    setCheckAll,

    noTotal
}) {

    // GENERAL
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const router = useNavigate();
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)


    const levels = ['Agent', 'Desk Manager', 'Office Manager',  'Admin']


    const getLevels = (toIndex) => {
        var tempLevels = ['Agent', 'Desk Manager', 'Office Manager',  'Admin']
        var indexLevel = [1,2,3,4];

        var retList = []
        var retListIndex = []
        for (var x=0; x<currentUser.access_level; x++) {
            retList.push(tempLevels[x])
            retListIndex.push(indexLevel[x])
        }

        if (toIndex) return retListIndex;
        return retList;
    }


    const empObj = {name: '', id: ''}
    //// CHEKES LEADS ////
    const handleCheck = (e) => {
        if (!e.target.checked) {
            setCheckAll(false)
        }

        setListCheked(prevValue => {
            if (!e.target.checked){
                return prevValue.filter((ch) => ch != i)
            } else {
                return [...prevValue, i]
            }
            
        })
    }
    //// STATUS ////
    const [theStatus, setTheStatus] = useState(clientData['status'])
    var statusOpt = ['New', 'Call again', 'No answer', 'Not Interested', 'Wrong Details', 'Deposit', 'In progress', 'Meeting']

    const handleStatus = async (e) => {
        setTheStatus(e.target.value)
        const result = await put(`/lead/${clientData.id}?${objectToQueryParams({status: e.target.value})}`)
        if (result.status == 200) {
            setNotiList(prevValue => [...prevValue, {text: "The lead's status has been successfully updated", isSucc: true}]) //"The lead's status has been successfully updated"
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
        }
    }
    //// OFFICE ////
    const [officesList, setOfficesList] = useRecoilState(officesList0)

    const testClients = () => {
        if (clientData.office !== null) {
            return clientData.office
        } else {
            return empObj
        }
    }

    const testUsers = () => {
        if (typeof clientData.office !== 'undefined') {
            return clientData.office
        } else {
            return empObj
        }
    }



    const [choosedOffice, setChoosedOffice] = useState(!noTotal ? testClients() : testUsers()) //// its ok VVV

    const handleChooseOffice = async (choosedOfficeX) => {
        setChoosedOffice(choosedOfficeX)
        const result = await put(`/lead/${clientData.id}?${objectToQueryParams({office_id: choosedOfficeX.id, desk_id: '', user_id: ''})}`)
        if (result.status == 200) {
            setChoosedDesk(empObj)
            setChoosedUser(empObj)
            setCurrentData((prevValue) => {
                var finalList = [...prevValue.data]
                finalList[clientDataIndex] = {
                    ...prevValue.data[clientDataIndex], 
                    ...result.data
                }
                return {
                    ...prevValue,
                    data: finalList
                }
            })
            setNotiList(prevValue => [...prevValue, {text: "The lead's Office has been successfully updated", isSucc: true}]) //
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
        }
    }

    useEffect(() => {
        const newDeskList = officesList.filter((offi) => offi.id == choosedOffice.id);
        if (newDeskList.length == 1) {
            setDeskList(newDeskList[0].desks)
        } else {
            setDeskList([])
        }
    }, [choosedOffice, officesList])

    //// DESK ////
    const [deskList, setDeskList] = useState([])
    const [choosedDesk, setChoosedDesk] = useState(clientData.desk != null ? clientData.desk : empObj)

    const handleChooseDesk = async (choosedDeskX) => {
        setChoosedDesk(choosedDeskX)
        const result = await put(`/lead/${clientData.id}?${objectToQueryParams({desk_id: choosedDeskX.id, user_id: ''})}`)
        if (result.status == 200) {
            setChoosedUser(empObj)
            setNotiList(prevValue => [...prevValue, {text: "The lead's Desk has been successfully updated", isSucc: true}])
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
        }
    }
    // useEffect(() => {
    //     const func = async () => {
    //         if (choosedDesk.id != '') {
    //             const result = await get(`/desk/${choosedDesk.id}/users`)
    //             console.log(result) //// yes?
    //             setUserList(result.data.users)
    //         }
    //     }
    //     func()
    // }, [choosedDesk])

    const updatedUsersList = async () => {
        // console.log(choosedDesk.id, 'aaa')
        if (choosedDesk.id != '') {
            const result = await get(`/desk/${choosedDesk.id}/users`)
            console.log(result.data.users)
            setUserList(result.data.users)
        }
    }


    //// USER ////
    const [userList, setUserList] = useState([])
    const [choosedUser, setChoosedUser] = useState(clientData.user)

    const handleChooseUser = async (choosedUserX) => {
        setChoosedUser(choosedUserX)
        const result = await put(`/lead/${clientData.id}?${objectToQueryParams({user_id: choosedUserX.id})}`)
        if (result.status == 200) {
            setNotiList(prevValue => [...prevValue, {text: "The lead's User has been successfully updated", isSucc: true}]) //
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
        }
    }

    // LEVEL
    const [access, setAcces] = useState(clientData.access_level)
    const handleAcces = async (e) => {
        setAcces(e.target.value)
        const result = await put(`/users/${clientData.id}?${objectToQueryParams({access_level: e.target.value})}`)
        if (result.status == 200) {
            setNotiList(prevValue => [...prevValue, {text: "The User's Access level has been successfully updated", isSucc: true}]) //
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) // "Something went wrong"
        }
    }


    //////////////////////////////////// USER //////////////////////////////////////////////

    // OFFICE
    const [choosedOffices, setChoosedOffices] = useState(clientData['offices'])

    const handleChooseOffices = async (choosedOfficesX, isInc) => {
        var listObj = []
        if (isInc) {
            setChoosedOffices((prevValue) =>  {
                const newValue = prevValue.filter((prev) => prev.id != choosedOfficesX.id)
                listObj = newValue
                return newValue
            })
        } else {
            setChoosedOffices((prevValue) =>  {
                listObj = [
                    ...prevValue,
                    choosedOfficesX
                ]
                return [
                    ...prevValue,
                    choosedOfficesX
                ]
            })
        }
        ////// UPLOAD CHANGES


        var objUpdate = {
            office_ids: listObj.map(item => item.id)
        }

        var updateDeskAlso = []
        if (isInc) {
            for (var x in choosedDesks) {
                if (choosedDesks[x].office_id != choosedOfficesX.id) {
                    updateDeskAlso.push(choosedDesks[x])
                }
            }
            objUpdate.desk_ids = updateDeskAlso.map(item => item.id)
            setChoosedDesks(updateDeskAlso)
        }

        const result = await put(`/users/${clientData.id}`, objUpdate)


        if (result.status == 200) {
            setNotiList(prevValue => [...prevValue, {text: "The lead's Office has been successfully updated", isSucc: true}]) 
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) // "Something went wrong"
        }
    }

    // DESKS
    const [choosedDesks, setChoosedDesks] = useState(clientData['desks'])
    const [desksList, setDesksList] = useState([])

    useEffect(() => {
        if (choosedOffices != null) {
            var newDeskList = [];
            for (var x in choosedOffices) {
                var officeTemp = officesList.find((offi) => offi.id == choosedOffices[x].id)
                if (officeTemp != null) {
                    newDeskList.push(...officeTemp.desks)
                }
            }
            setDesksList(newDeskList)
        } 
    }, [choosedOffices])

    const handleChoosedesks = async (deskObjX, isInc) => {
        var listObj = []
        if (isInc) {
            setChoosedDesks((prevValue) =>  {
                const newValue = prevValue.filter((prev) => prev.id != deskObjX.id)
                listObj = newValue
                return newValue
            })
        } else {
            setChoosedDesks((prevValue) =>  {
                listObj = [
                    ...prevValue,
                    deskObjX
                ]
                return [
                    ...prevValue,
                    deskObjX
                ]
            })
        }

        const result = await put(`/users/${clientData.id}`, {
            desk_ids: listObj.map(item => item.id)
        })
        ///// abcdefg
        //console.log(true, result.data)
        if (result.status == 200) {
            setNotiList(prevValue => [...prevValue, {text: "The lead's Desk has been successfully updated", isSucc: true}])
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
        }


    } 


    //////////// phone ////////
    const [lastCall, setLastCall] = useRecoilState(lastCall0)
    const makeCall = async  () => {
        
        setNotiList(prevValue => [...prevValue, {text: 'Call in process', isSucc: true}])
        setLastCall(clientData.id)
        
        setTimeout(() => {
            setNotiList(prevValue => prevValue.slice(1))
        }, 1500);

        const result = await post(`/lead/${clientData.id}/call`, {})
        
        if (result.ok) {
            setNotiList(prevValue => [...prevValue, {text: 'Success', isSucc: true}])
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
        setTimeout(() => {
            setNotiList(prevValue => prevValue.slice(1))
        }, 2000);
    }


    const bankColor = {
        'New': '',
        'Call again': 'rgb(233 234 251)',
        'No answer': 'rgb(250 241 226)',
        'Not Interested': 'rgb(250 202 203)',
        'Wrong Details': 'rgb(250 225 226)',
        'Deposit': 'rgb(182 237 230)',
        'In progress': 'rgb(226 248 236)',
        'Meeting': 'rgb(227 248 252)'
    }


    if (officesList != null)
  return (
    <tr className={`h-[52px] even:bg-gray-50 odd:bg-white ${lastCall === clientData.id && !noTotal && 'border-2 border-basic'}`} style={{backgroundColor: bankColor[theStatus]}}>
        {
            currentUser.access_level > 1 && (
                <td className="w-4 p-4">
                    <div className="flex items-center">
                        <input checked={listCheked.includes(i)} onChange={handleCheck} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500   focus:ring-2" />
                    </div>
                </td>
            )
        }
        {
            allThs.map((key0, z) => 
                ths.map((tz) => tz.display).includes(key0.display) && (
                    <td 
                    key={z}
                    onClick={
                        key0.key == 'first_name' ? () => router(`/clients/${clientData.id}`) 
                        : key0.display == 'name' ? () => router(`/users/${clientData.id}`) : () => {}
                    } 
                    style={{backgroundColor: bankColor[theStatus]}}
                    className={`px-6 py-4 ${(key0.key == 'first_name' || key0.display == 'name') ? `hover:underline font-semibold cursor-pointer sticky left-0 ${i%2 != 0 ? 'bg-gray-50' : 'bg-white'} px-6 py-4 font-medium text-gray-900 whitespace-nowrap` : ''} `}>
                        {/* USER LIST */}
                        {
                            key0.display == 'access_level' && (
                                currentUser.access_level >= 3 
                                ? (
                                    <Sel val={access} setVal={handleAcces} opts={getLevels()} optsVal={getLevels(true)}/>
                                ) : (
                                    levels[clientData[key0.display]-1]
                                )
                            )
                        }
                        {
                            key0.display == 'offices' && (
                                currentUser.access_level >= 3
                                ? (
                                    //console.log(clientData[key0.display].map(obj => obj.name))
                                    <SelectProMulty randomId={clientData.id + z} optionsData={[...officesList]} choosedOption={choosedOffices} setChoosedOption={handleChooseOffices} />
                                ) : (
                                    <div className='space-y-1'>
                                        {
                                            clientData[key0.display].map((offi, w) => (
                                                <span key={w} className="inline-flex mr-1 bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded">
                                                    {offi.name}
                                                </span>
                                            ))
                                        }
                                    </div>
                                )
                            )
                        }
                        {   
                            key0.display == 'desks' && (
                                currentUser.access_level >= 3
                                ? (
                                    <SelectProMulty randomId={clientData.id + z} optionsData={[...desksList]} choosedOption={choosedDesks} setChoosedOption={handleChoosedesks} />
                                ) : (
                                    <div className='space-y-1'>
                                        {
                                            clientData[key0.display].map((offi, w) => (
                                                <span key={w} className="inline-flex mr-1 bg-purple-100 text-purple-800 text-xs font-medium px-2 py-0.5 rounded">
                                                    {offi.name}
                                                </span>
                                            ))
                                        }
                                    </div>
                                )
                            )
                            
                        }
                        {/* LEADS TABLE */}
                        {
                            key0.display == 'phone' && (
                                clientData.callable == true ? (
                                    <button className={`but-emp rounded-full w-[32px] h-[32px] p-0 ${(lastCall === clientData.id && !noTotal) ? 'text-white bg-basic' : ''}`} onClick={makeCall}>
                                        <BsTelephoneFill  />
                                    </button>
                                ) : (
                                    <button className='w-[32px] h-[32px] p-0 hidden'>

                                    </button>
                                )
                            )
                        }
                        {
                        //////////////////////
                            key0.display == 'office' && (
                                currentUser.access_level == 4
                                ? (<SelectPro display={key0.display} randomId={clientData.id  + z} optionsData={[empObj, ...officesList]} choosedOption={choosedOffice} setChoosedOption={handleChooseOffice}  />)   
                                : (clientData.office != null && clientData.office.name)
                            )
                        ////////////////////////
                        }
                        {
                            key0.display == 'desk' && (
                                currentUser.access_level >= 3
                                ? (<SelectPro display={'desk'} randomId={clientData.id + z} optionsData={[empObj, ...deskList]} choosedOption={choosedDesk} setChoosedOption={handleChooseDesk}  />)   
                                : (clientData.desk != null && clientData.desk.name)
                            )
                        }
                        {
                            key0.display == 'user' && (
                                currentUser.access_level >= 2
                                ? (<SelectPro display={'user'} onClick0={updatedUsersList} randomId={clientData.id + z} optionsData={[empObj, ...userList]} choosedOption={choosedUser} setChoosedOption={handleChooseUser}  />)   
                                : (clientData.user != null && clientData.user.name)
                            )
                        }
                        {
                            key0.display == 'status' && (
                                <Sel val={theStatus} setVal={handleStatus} opts={statusOpt} optsVal={statusOpt} />
                            )
                        }
                        {
                            key0.display == 'comment' && (
                                <span>
                                    {
                                        clientData[key0.key] != null && (
                                            clientData[key0.key].substring(0,50)       
                                        )
                                    }
                                    {
                                        clientData['comment'] != null && clientData['comment'].length > 50 && (
                                            '...'
                                        )
                                    }
                                </span>
                            )
                        }
                        {
                            key0.display == 'country' && (
                                isoToCountry[clientData[key0.key]]
                            )
                        }

                        {
                            key0.display == 'language' && (
                                <SelectLang clientId={clientData.id} defaultLang={clientData.lang} />
                            )
                        }

                        {
                            (key0.display != 'phone' &&  key0.display != 'language' && key0.display != 'country' && key0.display != 'comment' &&  key0.display != 'access_level' && key0.display != 'offices' && key0.display != 'office' && key0.display != 'desks'&& key0.display != 'status' && key0.display != 'desk' && key0.display != 'user') && (
                                <span>
                                    {clientData[key0.key]}
                                </span>
                            )
                        }
                    </td>
                )
            )
        }
    </tr>
  )
}
