import ControlesTab from '../../components/tables/ControlesTab'
import React, { useEffect, useState } from 'react'
import { get } from '../../lib/apiHandler';
import Table from '../../components/tables/Table-new';
import objectToQueryParams from '../../lib/objToParams';
import { format } from 'date-fns';

import { checkedLeads, notiList0, currentUser0, toForce0 } from '../../lib/states';
import { useRecoilState } from 'recoil';

export default function Clients() {
    const [toForce, SetToForce] = useRecoilState(toForce0)


    const [currentData, setCurrentData] = useState(null)
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    const clientFilter = JSON.parse(localStorage.getItem('clientFilter'))

    if (clientFilter !== null) {
      clientFilter.startDate = format(new Date(), 'yyyy-MM-dd')
      clientFilter.endDate = format(new Date(), 'yyyy-MM-dd')
      clientFilter.page = 1;
      if (clientFilter.version !== "1") {
        console.log('update version to 1')
        clientFilter.version = '1';
        clientFilter.sort_by = 'last_assign_date';
      }
    }
    const objDates = {
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
    }
    
    const [objFilter, setObjFilter] = useState(
      clientFilter != null
      ? clientFilter
      : {
        sort_by: 'last_assign_date',
        version: '1',
        sort_direction: 'desc',
        page: 1,
        per_page: 10,
        filter: JSON.stringify({}),
        ...objDates
      }
    )
    
    const allThs = [
      {key: 'id', display: 'id'},
      {key: 'first_name', display: 'first name'},
      {key: 'last_name', display: 'last name'},
      {key: 'brand', display: 'brand'},
      {key: 'phone', display: 'phone'},
      {key: 'email', display: 'email'},
      {key: 'status', display: 'status'},
      {key: 'office_name', display: 'office'},
      {key: 'desk_name', display: 'desk'},
      {key: 'user_name', display: 'user'},
      {key: 'country', display: 'country'},

      {key: 'lang', display: 'language'},

      
      {key: 'registration_time', display: 'registration time'},
      {key: 'created_at', display: 'created at'},
      
      {key: 'transaction_sum', display: 'transaction sum'},
      
      
      {key: 'net_deposit', display: 'net_deposit'},
      {key: 'ftd_time', display: 'ftd time'},
      {key: 'last_time_deposit', display: 'last time deposit'},
      
      
      {key: 'city', display: 'city'},
      {key: 'address', display: 'address'},
      {key: 'zip', display: 'zip'},
      {key: 'original_agent', display: 'original_agent'},
      {key: 'original_status', display: 'original_status'},
      {key: 'birthday', display: 'birthday'},
      
      
      
      {key: 'comment', display: 'comment'},

      {key: 'last_assign_date', display: 'Last assign date'},
      
      {key: 'lead_source', display: 'lead source'},
    ]

    if (currentUser.access_level >= 2) {
      allThs.push({key: 'file_name', display: 'file name'})
    }

    
    const [uploadForce, setUploadForce] = useState(false)
    const [choosedAfterRem, setChoosedAfterRem] = useState('')
    const [notiList, setNotiList] = useRecoilState(notiList0)


    const listCol = JSON.parse(localStorage.getItem('columns-client'))
    const [ths, setThs] = useState(listCol != null ? listCol.list : allThs)

    useEffect(() => {
      if (listCol?.version !== "1") {
        localStorage.setItem('columns-client', JSON.stringify({
          version: "1",
          list: allThs
        }))
        setThs(allThs)
      } else {
        localStorage.setItem('columns-client', JSON.stringify({
          version: "1",
          list: ths
        }))
      }
    }, [ths])


    useEffect(() => {
      var isChange = false;

      var tempFill = localStorage.getItem('clientFilter')
      if (tempFill === null) {
        tempFill = {}
      } else {
        tempFill =  JSON.parse(tempFill)
      }
      const oldObjFilter = tempFill


      for (var k of Object.keys(objFilter)) {
        if (objFilter[k] !== oldObjFilter[k]) {
          isChange = true
        }
      }

      if (isChange) {
        func()
      }
    }, [toForce]); //setUploadForce(prevValue => !prevValue)



    useEffect(() => {
      func()
    }, [uploadForce])

    // useEffect(() => {
    //   const isWordInMyList = Object.keys(JSON.parse(objFilter.filter)).some(word => ['status', 'office_name', 'desk_name', 'user_name'].includes(word));
    //   if (isWordInMyList) {
    //     const timer = setTimeout(() => {
    //       func()
    //     }, 1500);
    
    //     return () => {
    //       clearTimeout(timer);
    //     };
    //   } else {

    //     func()
    //   }
    // }, [objFilter, uploadForce]);


    const func = async () => {
      //console.log(objFilter.filter)
      localStorage.setItem('clientFilter', JSON.stringify(objFilter))
      setCurrentData(null)
      const paramStr = objectToQueryParams(objFilter)
      const result = await get(`/leads?${paramStr}`)
      if (result.ok) {
        setCurrentData(result.data)
      } else {
        setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
      }
      //console.log(result);
    }

    // useEffect(() => {
        
    //     func();
    // }, [objFilter, uploadForce])

    //const [listCheked, setListCheked] = useRecoilState(checkedLeads)
    const [listCheked, setListCheked] = useState([])


  return (
    <div className='flex flex-col space-y-2 sm:space-y-5'>
        <ControlesTab 
          setChoosedAfterRem={setChoosedAfterRem}
          setUploadForce={setUploadForce}
          objFilter={objFilter}
          setObjFilter={setObjFilter}
          ths={ths}
          allThs={allThs}
          setThs={setThs}
          listCheked={listCheked}
          currentData={currentData}
        />
        <Table 
          currentData={currentData} setCurrentData={setCurrentData}
          allThs={allThs}
          ths={ths}
          objFilter={objFilter} setObjFilter={setObjFilter}
          noTotal={false}
          setUploadForce={setUploadForce}
          choosedAfterRem={choosedAfterRem} setChoosedAfterRem={setChoosedAfterRem}
          listCheked={listCheked} setListCheked={setListCheked}
        />
    </div>
  )
}
