import { del, get, put, post } from '../../lib/apiHandler';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import TablePro from '../../components/tables/TablePro';
import Skeleton from 'react-loading-skeleton';

import { FiMail } from 'react-icons/fi';
import { FaRegSave } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrEdit } from 'react-icons/gr';
import { IoClose } from 'react-icons/io5';

import { useRecoilState } from 'recoil';
import { notiList0, loading, currentUser0, popWin0 } from '../../lib/states';
import country_two_iso from '../../lib/country_two_iso';
import { formDataToObject } from '../../lib/funcs';
import isoToCountry from '../../lib/isoToCountry';
import SelectLangs from '../../components/inps2/SelectLangs';

import langIsoToName from "../../lib/langIsoToName"
import SelectCountry from '../../components/inps2/SelectCountry';


export default function UserPage() {
  const router = useNavigate();
  const [user, setUser] = useState(null)
  const { userId } = useParams();
  const level = ['Agent', 'Desk manager', 'Office manager', 'admin']
  const [OfficesList, setOfficesList] = useState(null)
  const [desksLen, setDeskLen] = useState(0)
  const [editUserMode, setEditUserMode] = useState(false)

  const [isLoading, setIsLoading] = useRecoilState(loading)
  const [notiList, setNotiList] = useRecoilState(notiList0)
  const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

  useEffect(() => {
    const func = async () => {
      setIsLoading(true)
      const result = await get(`/user/${userId}`)
      if (result.status == 200) {
        setUser(result.data)
        
        const result3 = await get(`/user/${userId}/offices`)
        if (result3.status == 200) {
          setOfficesList(result3.data)
          
          var count = 0;
          result3.data.map(offi => offi.desks.map(des => count++))
          setDeskLen(count)
    
          await getTransactions()
        } else {
          setNotiList(prevValue => [...prevValue, {text: result3.data.message, isSucc: false}])
        }
      } else {
        setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
      }
      setIsLoading(false)
    }
    func();
  }, [userId])


  //// Transactions
  const [dataTrans, setDataTrans] = useState(null)
  const getTransactions = async () => {
    const result = await get(`/user/${userId}/transactions`)
    setDataTrans(result.data)
  }

  const updateUser = async (e) => {
    e.preventDefault()
    var formObj = formDataToObject(new FormData(e.target));
    var obj = {
      ...formObj,
      countries: country_two_iso.length === choosedCountry.length ? [] : choosedCountry.map(country => country.id),
      languages: langIsoToName.length === choosedLang.length ? [] : choosedLang.map(country => country.id)
    }
    if (obj['password'] === '') {
      delete obj.password
    }
    console.log(obj)
    setIsLoading(true)
    const result = await put(`/users/${user.id}`, obj)
    if (result.ok) {
      setNotiList(prevValue => [...prevValue, {text: "User successfully update", isSucc: true}])
      setUser(prevValue => {return {...prevValue, ...result.data}})
      if (currentUser.id === result.data.id) {
        setCurrentUser(prevValue => {return {...prevValue, ...result.data}})
      }
      setEditUserMode(prevValue => !prevValue);

    } else {
      setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
    }
    setIsLoading(false)
  }
  const [popWin, setPopWin] = useRecoilState(popWin0)
  const deleteUser = async () => {

    const newFunc = async () => {
      const result = await post(`/users/delete`, {
        ids: [user.id]
      })
      if (result.status == 200) {
        setNotiList(prevValue => [
          ...prevValue,
          {text: result.data.message, isSucc: true}
        ])
        router('/users')
      } else {
        setNotiList(prevValue => [
          ...prevValue,
          {text: result.data.message, isSucc: false}
        ])
      }
    }

    setPopWin({
      disable: true,
      msg: 'Are you sure you want to delete this User?',
      func: newFunc
    })

  }


  const [updateLog, setUpdateLog] = useState(true)
  const removeLog = async (logId) => {
    const result = await del(`/log/${logId}`)
    if (result.status == 200) {
        setUpdateLog(prevValue => !prevValue)
        setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: true}])
    } else {
        setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
    }
  }

  const [choosedCountry, setChoosedCountry] = useState([]) //typeof user?.countries === 'undefined' ? [] : user.countries

  useEffect(() => {
    if (user !== null) {
      setChoosedCountry(user?.countries.map((iso) => {return { id: iso, name: isoToCountry[iso]} } ))
      setChoosedLang(user?.languages.map((iso) => {return { id: iso, name: langIsoToName[iso]}}))
    }
  }, [user])

  const [choosedLang, setChoosedLang] = useState(typeof user?.languages === 'undefined' ? [] : user.languages)

  useEffect(() => {
    console.log(choosedLang)
  }, [choosedLang])

  return (
    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
      <div className='flex flex-col space-y-4 col-span-2'>
        <div className='card p-0 relative'>
          <div className='border-b w-full flex flex-row items-center'>
            {
              !editUserMode
              ? (
                <>
                  <div className='bg-basic m-5 rounded-full w-20 h-20 flex justify-center items-center text-white text-2xl font-semibold'>
                      {user != null && user.name.slice(0,1)}
                  </div>
                  <div className='flex flex-col text-md'>
                    {
                      user != null 
                      ? <span className='text-2xl font-semibold w-auto'>{user.name}</span>
                      : <span className='w-[150px]'><Skeleton /></span>
                    }
                      <div className='flex flex-row items-center justify-center text-gray-700 space-x-2'>
                          <FiMail className='relative top-[1.6px]' />
                          {
                            user != null 
                            ? <span>{user.email}</span>
                            : <span className='w-[150px]'><Skeleton /></span>
                          }
                      </div>
                  </div>
                
                
                </>
              ) : (
                  <form className='w-full py-8' onSubmit={updateUser}>
                    <div className='grid grid-cols-2 gap-4 p-4 [&>input]:shadow-none [&>input]:inp'>
                      <div className='flex flex-col space-y-1'>
                        <span className='text-xs'>USER NAME</span>
                        <input
                          defaultValue={user.name} 
                          name="name"
                          type='text' className='inp px-4 text-md text-gray-700'
                        />
                      </div>


                      <div className='flex flex-col space-y-1'>
                        <span className='text-xs'>EMAIL</span>
                        <input
                          defaultValue={user.email}
                          name="email"
                          type='email' className='inp px-4 text-md text-gray-700'
                        />
                      </div>


                      <div className='flex flex-col space-y-1'>
                        <span className='text-xs'>PASSWORD</span>
                        <input
                          defaultValue={''} 
                          className='inp px-4 text-md text-gray-700'
                          placeholder='*******' type='password'
                          name='password'
                          autoComplete="new-password"
                        />
                      </div>

                      <div className='flex flex-col space-y-1'>
                        <span className='text-xs'>EXTENSTION</span>
                        <input 
                          className='inp px-4 text-md text-gray-700'
                          defaultValue={user?.extension}
                          name="extension"
                        />
                      </div>

                      {
                        currentUser.access_level >= 2 && (
                          <>
                          
                            <div className='flex flex-col space-y-1'>
                              <span className='text-xs'>Leads Allocation</span>
                              <input 
                                className='inp px-4 text-md text-gray-700'
                                pattern="[0-9]*"
                                defaultValue={user.leads_allocation}
                                name="leads_allocation"
                              />
                            </div>
                          
                            <div className='flex flex-col space-y-1'>
                              <span className='text-xs'>COUNTRIES</span>
                              <SelectCountry choosedCountry={choosedCountry} setChoosedCountry={setChoosedCountry} />
                            </div>

                            <div className='flex flex-col space-y-1'>
                              <span className='text-xs'>Language</span>
                              <SelectLangs choosedLang={choosedLang} setChoosedLang={setChoosedLang} />
                            </div>

                          </>
                        )
                      }

                    </div>


                    <div className='flex flex-row justify-end space-x-1 px-4'>
                      <button type="button" onClick={() => deleteUser()} className='but-red font-semibold space-x-1'>
                        <MdDelete className='text-[18px]'/>
                        <span className='text-[16px]'>
                          delete
                        </span>
                      </button>
                      <button type='submit' className='but font-semibold space-x-1'>
                        <FaRegSave className='text-lg'/>
                        <span className='text-[16px]'>
                          Save
                        </span>
                      </button>
                    </div>




                  </form>
              )
            }
            <div className='absolute top-0 right-0 flex flex-row p-4 space-x-2'>
              {
                user != null
                ? (
                  <>
                    {/* <Cir icon={<TbMail className='text-xl' />} /> */}
                    {/* <Cir icon={<LuPhone />} /> */}
                    
                      {
                        (currentUser.access_level > user.access_level || user.id == currentUser.id) && (
                          !editUserMode
                          ? (
                          <span onClick={() => setEditUserMode(prevValue => !prevValue)}>
                            <Cir icon={<GrEdit />} />
                          </span>
                          ) : (
                            <span onClick={() => {setEditUserMode(prevValue => !prevValue); setChoosedCountry(user?.countries.map((iso) => {return { id: iso, name: isoToCountry[iso]}})); setChoosedLang(user?.languages.map((iso) => {return { id: iso, name: langIsoToName[iso]}}));}}>
                              <Cir icon={<IoClose />} />
                            </span>
                          )
                        )
                      }
                    
                  </>
                ) : (
                  <span className='h-10 w-10'>
                    <Skeleton circle={true} className='h-10 w-10' />
                  </span>
                )
              }
            </div>
          </div>
          <div className='flex flex-row [&>div]:w-1/3 [&>div]:border'>
              <Rib up="Level" bott={user != null ? level[user.access_level - 1] : null} />
              <Rib up="Allocations" bott={user?.leads_allocation} />
              <Rib up="Leads amount" bott={user != null ? user.leads_count : null} />
          </div>
          <div className='flex flex-row [&>div]:w-1/3  [&>div]:border'>
              <Rib up="Total Transactions" bott={user != null ? user.transaction_sum : null} />
              <Rib up="Countries" bott={
                user != null ? (
                  <div>
                    {
                      user?.countries.map((iso1, a) => <span key={a} className='buble mr-1 mb-1'>{isoToCountry[iso1]}</span>)
                    }
                    {
                      user?.countries.length === 0 
                      && <span className='buble mr-1 mb-1'>Any Country</span>
                    }
                  </div>
                ) : null
              } />

              <Rib up="language" bott={
                user != null ? (
                  <div>
                    {
                      user?.languages?.map((iso1, a) => <span key={a} className='buble mr-1 mb-1'>{langIsoToName[iso1]}</span>)
                    }
                    {
                      (user?.languages?.length === 0 || typeof user?.languages === 'undefined')
                      && <span className='buble mr-1 mb-1'>Any Language</span>
                    }
                  </div>
                ) : null
              } />
              
          </div>
        </div>
        {/* <div>
          <DefaultRoltte user={user} />
        </div> */}
        <div className='space-y-1'>
          {/* <Table2 title="Transaction record" ths={['date', 'amount', 'user']} /> */}
          <TablePro isSRCready={user != null ? true : false} title="Transaction record" src={user != null ? `/user/${user.id}/transactions` : ''} ths={['date', 'amount', 'lead']} />
        </div>
        <div className='space-y-1'>
          <TablePro updateLog={updateLog} deletability={removeLog} isSRCready={user != null ? true : false}  title="Logs record" src={user != null ? `/user/${user.id}/logs` : ''} ths={["created_at", "description", 'subject_id']} />
          {/* <Table2 title="Logs record" ths={['action date', 'event', 'changed filed', 'old value', 'new value', 'user action']} /> */}
        </div>
      </div>
      <div className='space-y-4'>
        <div className='card'>
          <div className='flex flex-row [&>div]:w-1/2 [&>div]:border [&>div]:py-3'>
            <Rib up="Offices amount" bott={OfficesList != null ?  OfficesList.length : 0} />
            <Rib up="Desks amount" bott={desksLen} />
          </div>
        </div>
        {
          OfficesList != null 
          ? OfficesList.map((offi, z) => {
              return (
                <div className='space-y-1' key={z}>
                  <button className='but text-lg px-6' onClick={() => router(`/offices/${offi.id}`)}>{offi.name}</button>
                  <div className='space-y-4'>
                    {
                      offi.desks.map((des, x) => {
                        return (
                        <div key={x} className='card flex flex-col'>
                          <div className='flex justify-center items-center py-8'>
                            <button onClick={() => router(`/offices/${offi.id}/${des.id}`)} className='but-emp text-lg px-6'>
                              {des.name}
                            </button>
                          </div>
                          <div className='flex flex-row [&>div]:w-1/2 [&>div]:border [&>div]:py-3'>
                            <Rib up="Leads" bott={des.leads_count} />
                            <Rib up="Transactions" bott={des.transaction_sum != null ? des.transaction_sum : 0} />
                          </div>
                        </div>
                      )})
                    }
    
                  </div>
                </div>
    
              )
          })
          : (
              <div className='space-y-1'>
                <span className=''><Skeleton width={250} height={40} /></span>
                <div className='space-y-4'>
                  <div className='card flex flex-col'>
                    <div className='flex justify-center items-center py-11'>
                      <span><Skeleton width={150} /></span>
                    </div>
                    <div className='flex flex-row [&>div]:w-1/2 [&>div]:border [&>div]:py-3'>
                      <Rib up="Leads" bott={null} />
                      <Rib up="Transactions" bott={null} />
                    </div>
                  </div>

                </div>
              </div>
          )
        }
      </div>
    </div>
  )
  
}



function Rib({up, bott}) {
  return (
      <div className='flex flex-col p-4 text-center space-y-1'>
          <span className='uppercase text-sm text-gray-600'>{up}</span>
          {
            bott != null
            ? <span className='text-lg font-semibold'>{bott}</span>
            : <div className='flex justify-center items-center'>
              <span className='text-lg font-semibold w-[80%]'>{<Skeleton />}</span>
            </div>
          }
      </div>
  )
}

function Cir({icon}) {
return (
  <div className='border rounded-full w-10 h-10 flex items-center justify-center text-gray-600 hover:bg-basic hover:text-white cursor-pointer'>
      {icon}
  </div>
)
}