import React, { useEffect, useState, useRef } from 'react'

export default function SelectProMulty({
    optionsData,
    choosedOption, setChoosedOption, titleKey,
    randomId,
    styleBut,
    toNothing,
    allBut,
}) {
    allBut = true;
    // if (toNothing) {
    //     optionsData = [{id: 0, name: 'null'}, ...optionsData]
    // }

    const [optionsData1, setOptionsData1] = useState(toNothing ? [{id: 0, name: 'null'}, ...optionsData] : optionsData)
    const [dropped, setDropped] = useState(false)
    const [titleBut, setTitleBut] = useState('')
    const [optionsDataAfterSearch, setOptionsDataAfterSearch] = useState(toNothing ? [{id: 0, name: 'null'}, ...optionsData] : optionsData)
    const [textSearch, setTextSearch] = useState('')
    const myElementRef = useRef();
    // const [hei , setHei] = useState(250)
    const [toUp, setToUp] = useState(null)

    useEffect(() => {
        setOptionsData1(toNothing ? [{id: 0, name: 'null'}, ...optionsData] : optionsData)
    }, [optionsData])
    
    useEffect(() => {
        if (dropped) {
            var rect = myElementRef.current.getBoundingClientRect();
            var end = rect.top + rect.height + 255;
            setToUp(window.innerHeight > end)
        } else {
            setToUp(null)
        }
    }, [dropped])



    useEffect(() => {
        var newList = optionsData1.filter(offi => offi.name.toLowerCase().includes(textSearch.toLowerCase()))
        if (textSearch !== '') {
            newList = newList.filter(obj => obj.name !== "null")
        }
        setOptionsDataAfterSearch(newList);
    }, [textSearch, optionsData1])
    
  return (
    <div id={`drop-col-${randomId}`} ref={myElementRef}>
        <button type='button' onClick={() => setDropped(prevValue => !prevValue)} className={`inp !hover:bg-none flex flex-row items-center justify-between h-[38px] px-2 min-w-[160px] ${styleBut}`}>
            <span className='truncate space-x-1'>
                {
                    choosedOption?.length > 3 ? (
                        <span>{choosedOption?.length} items</span>
                    ) : (
                        choosedOption?.map((opt, o) => (
                            <span key={o} className='bg-red-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded block"'>
                                {opt.name}
                            </span>
                        ))
                    )
                
                }
                {/* {
                    titleBut !== null && titleBut !== '' &&
                    
                        titleBut.split(', ').length > 4 ? (
                            <span>ahh</span>
                        ) : (
                            titleBut.split(', ').map((tit, z) => (
                                <span key={z} className='bg-red-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded block"'>
                                    {tit === 'null' ? 'empty' : tit} 
                                </span>
                            ))
                        )
                    
                } */}
            </span>
            <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/></svg>
        </button>
        {
            dropped && (
                <DroppedList allBut={allBut} toUp={toUp} setDropped={setDropped} randomId={randomId} textSearch={textSearch} setTextSearch={setTextSearch} setChoosedOption={setChoosedOption} titleKey={titleKey} choosedOption={choosedOption} optionsDataAfterSearch={optionsDataAfterSearch} />
            )
        }

    </div>
  )
}





////////// DROP LIST //////////

function DroppedList({
    textSearch, setTextSearch,
    optionsDataAfterSearch,
    choosedOption, setChoosedOption, titleKey,
    randomId,
    setDropped,
    toUp,
    allBut,
}) {
    useEffect(() => {
        const handleBodyClick = (e) => {
            var element = e.target
            var isInside = false;
            while (true) {
                if (`drop-col-${randomId}` == element.id) {
                    isInside = true
                    break
                }
                element = element.parentElement
                if (element == null) {
                    break
                }
            }
            if (!isInside) {
                setDropped(false)
            }
        };
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [])

    const isIncludes = (optionObj_id) => {
        var isTrue = false
        for (var z in choosedOption) {
            if (choosedOption[z].id == optionObj_id) {
                isTrue = true
            }
        }
        return isTrue
    }
    const handleSelAll = (e) => {
        for (var optionObj of optionsDataAfterSearch) {
            setChoosedOption(optionObj, !e.target.checked, titleKey)
        }
    }


    const ulElement = useRef();
    const getHei = () => {
        var len = optionsDataAfterSearch.length > 5 ? 5 : optionsDataAfterSearch.length
        return len * 40 + 45;
    }

    return (
        <div className={`relative top-1 z-20 ${toUp === null && 'hidden'}`}>
            <div 
            style={{
                bottom: toUp ? -52 : getHei()
            }}
            className={`absolute min-w-[150px]`}>
                <div className='bg-white border border-b-0 p-2'>
                    <div className='flex flex-row'>
                        {
                            allBut && (
                                <div className='flex items-center justify-center pr-2'>
                                    {/* <button onClick={handleSelAll} className='bg-basic hover:bg-basic_hov active:bg-basic_foc w-4 h-4 rounded-md'></button> */}
                                    <input
                                        onChange={handleSelAll}
                                        type="checkbox" 
                                        checked={optionsDataAfterSearch.filter(optionObj => isIncludes(optionObj.id)).length === optionsDataAfterSearch.length}
                                    />
                                </div>
                            )
                        }
                        <input value={textSearch} placeholder='Search...' onChange={(e) => setTextSearch(e.target.value)} className='inp bg-white shadow-none' />
                    </div>
                </div>
                <div className={`border z-30 scrollbar-thin w-full scrollbar-thumb-gray-900 scrollbar-transparent absolute h-auto overflow-y-auto bg-white overflow-hidden divide-y divide-gray-100`}>
                    <ul ref={ulElement} className='text-sm text-gray-700 relative max-h-[200px]'>
                        {
                            optionsDataAfterSearch.map((optionObj, z) => {
                                return (
                                    <div 
                                    key={z}
                                    className={`flex flex-row items-center  space-x-2 px-2 hover:bg-gray-50`}
                                    >
                                        <input
                                            checked={isIncludes(optionObj.id)}
                                            onClick={() => {setChoosedOption(optionObj, isIncludes(optionObj.id), titleKey)}}
                                            onChange={() => {}}
                                            type="checkbox"
                                            id={`li-${z}`} 
                                        />
                                        <label className='normal-case w-full min-h-[40px] flex items-center' htmlFor={`li-${z}`}>
                                            {
                                                optionObj.name === 'null' ? (
                                                    ''
                                                ) : (
                                                    optionObj.name
                                                )
                                            }
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}