import axios from "axios";
//const BASE_URL = "https://api.echoeclipse.live";
const BASE_URL = "https://api.eclipse-crm.com";


export async function get(endpoint) {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      "authorization": `Bearer ${token}`,
      withCredentials: true,
    }
  };
  try {
    const response = await axios.get(`${BASE_URL}${endpoint}`, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response
  } catch (error) {
    error.response.ok = false;
    return error.response
  }
}

export async function post(endpoint, body) {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      "authorization": `Bearer ${token}`,
      withCredentials: true,
    }
  };
  try {
      const response = await axios.post(`${BASE_URL}${endpoint}`, body, config);
      response.ok = response.status >= 200 && response.status < 300;
      return response
    } catch (error) {
      error.response.ok = false;
      return error.response
  }
}

export async function put(endpoint, body) {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      "authorization": `Bearer ${token}`,
      withCredentials: true,
    }
  };
  try {
      const response = await axios.put(`${BASE_URL}${endpoint}`, body, config);
      response.ok = response.status >= 200 && response.status < 300;
      return response
    } catch (error) {
      error.response.ok = false;
      return error.response
  }
}



export async function del(endpoint) {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      "authorization": `Bearer ${token}`,
      withCredentials: true,
    }
  };
  try {
    const response = await axios.delete(`${BASE_URL}${endpoint}`, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response
  } catch (error) {
    error.response.ok = false;
    return error.response
  }
}
